/*jshint
    browser: true,
    esversion: 9
*/

$(() => {
    $(document)
    .on('click', '[data-focus]', function (evt) {
        const $this = $(this);
        const target = $this.data('focus');
        const $target = $(target);

        setTimeout(() => {
            $target[0].focus();
        }, 128);
    })
    ;

    $('#contact_form').bootstrapValidator({
        // To use feedback icons, ensure that you use Bootstrap v3.1.0 or later
        feedbackIcons: {
            valid: 'glyphicon glyphicon-ok',
            invalid: 'glyphicon glyphicon-remove',
            validating: 'glyphicon glyphicon-refresh'
        },
        fields: {
            name: {
                validators: {
                    stringLength: {
                        min: 2,
                        // message:'Introduceți cel puțin 2 caractere.'
                        message: 'Please enter at least 2 characters.'

                    },
                    notEmpty: {
                        // message: 'Furnizați prenumele dvs.'
                        message: 'Please enter your name.'
                    }
                }
            },

            email: {
                validators: {
                    notEmpty: {
                        // message: 'Vă rugăm să furnizeze adresa de e-mail.'
                        message: 'Please enter your e-mail address.'
                    },
                    emailAddress: {
                        // message: 'Vă rugăm să furnizați o adresă de e-mail validă.'
                        message: 'Please enter a valid email address.'
                    }
                }
            },

            comment: {
                validators: {
                    stringLength: {
                        min: 10,
                        max: 200,
                        // message:'Introduceți cel puțin 10 de caractere și nu mai mult de 200.'
                        message: 'Please enter at least 10 characters and less than 200 characters.'
                    },
                    notEmpty: {
                        // message: 'Vă rugăm să furnizați o descriere a proiectului.'
                        message: 'Please provide a project description.'
                    }
                }
            }
        }
    })
        .on('success.form.bv', function (e) {
            e.preventDefault();
            $('#contact_form').data('bootstrapValidator').resetForm();

            if ($('#error-message').length) {
                $('#error-message').slideUp({ opacity: "show" }, "slow");
            }

            let $form = $(e.target);
            let $btn = $form.find('button.page-scroll');
            let $ta = $form.find('textarea[name=comment]');

            let data = $form.serialize();
            data += "&asf=" + Date.now().toString(27);

            $btn.prop('disabled', true);
            $btn.addClass('loading');

            $.post($form.attr('action'), data, (result) => {
                if (result) {
                    $ta.val('');
                    $('#success-message').slideDown({ opacity: "show" }, "slow");
                    setTimeout(() => {
                        $('#success-message').slideUp({ opacity: "show" }, "slow");
                    }, 3000);
                }
                else {
                    $('#error-message').slideDown({ opacity: "show" }, "slow");
                }
                $btn.prop('disabled', false);
                $btn.removeClass('loading');
                return result;
            }, 'json');
        });
});

